<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register fiveitems">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="item.active ? 'active' : item.finish ? 'done' : ''"
                  @click="
                    item.finish && stepdetail < 5 && changeStep(item.linkItems)
                  "
                >
                  <a href="#">
                    <span class="icons icon-checkmark"></span>
                    <span class="number">{{ item.step }}</span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <!-- <div class="contentregister register-content"> -->
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box w-630">
              <h3 class="title-md">{{ $t("DetailStep2.title") }}</h3>

              <div class="form-group">
                <label class="light">{{ $t("DetailStep2.title_acc") }}</label>
                <div
                  class="dropdown select"
                  @click="getfocus('accommodation_owned_by')"
                  @mouseleave="mouseOver('accommodation_owned_by')"
                >
                  <a data-toggle="dropdown" data-display="static" id="accommodation_owned_by">
                    <v-autocomplete
                      v-model="ownerDetail.accommodation_owned_by"
                      :items="itemsAcc"
                      label
                      :placeholder="$t('SignupStep.select_one')"
                      :no-data-text="$t('SignupStep.nodata')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                      @mouseenter="getfocus('accommodation_owned_by')"
                      @mouseleave="mouseOver('accommodation_owned_by')"
                    ></v-autocomplete>
                  </a>
                </div>
                <span
                  class="error-text-input"
                  v-if="!ownerDetail.accommodation_owned_by"
                >{{ $t("Alert.pleasefilldata") }}</span>
              </div>

              <div class="form-group mt-md-5 mt-4">
                <h3 class="f18 medium mb-3">{{ $t("DetailStep2.title_fullname") }}</h3>
                <span
                  class="error-text-input"
                  v-if="!ownerDetail.owner_name.length"
                >{{ $t("Alert.pleasefilldata") }}</span>

                <div class="addname-option">
                  <template v-for="(item, index) in ownerDetail.owner_name">
                    <div :key="index" class="row">
                      <div class="cols">
                        <button
                          class="btn btn-mininus btn-hover-red"
                          type="button"
                          @click="removeItemName(item, index)"
                        ></button>
                      </div>
                      <div class="cols">
                        <div class="form-group">
                          <label class="light">
                            {{
                            $t("DetailStep2.title_fname")
                            }}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            name
                            placeholder
                            v-model="item.first_name"
                          />
                        </div>
                      </div>
                      <div class="cols">
                        <div class="form-group">
                          <label class="light">
                            {{
                            $t("DetailStep2.title_lname")
                            }}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            name
                            placeholder
                            v-model="item.last_name"
                          />
                        </div>
                      </div>
                    </div>
                  </template>

                  <div
                    class="d-flex flex-row align-center justify-start"
                    :class="
                      ownerDetail.accommodation_owned_by
                        ? 'btn-addname'
                        : 'btn-addname-disbled'
                    "
                    @click="
                      ownerDetail.accommodation_owned_by && openDialogAddName()
                    "
                  >
                    <button class="btn btn-plus btn-hover-red mb-0 mr-6" type="button"></button>
                    <label
                      class="light mb-0"
                      style="white-space: nowrap; cursor: pointer"
                    >{{ $t("DetailStep2.btn_add_another") }}</label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="light">
                  {{ $t("DetailStep2.title_alternative") }}
                  <span class="graylight">
                    {{
                    $t("DetailStep2.label_alternative")
                    }}
                  </span>
                </label>
                <input type="text" class="form-control" name v-model="ownerDetail.alternative_name" />
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <button
              type="button"
              class="btn gl-bt hover_btn"
              style="text-transform: capitalize !important"
              :disabled="!cangonextstep"
              @click="updateOwnerDetail(3)"
            >
              <span>{{ $t("DetailStep2.btn_next") }}</span>
            </button>

            <v-dialog
              v-model="showDialogAddFullName"
              persistent
              max-width="420"
              style="border-radius: 24px !important"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5>{{ $t("DetailStep2.dialog_addname") }}</h5>
                  <button type="button" class="modal-close" @click="cancelAddName()"></button>
                </div>

                <div class="modal-body">
                  <div class="form-group">
                    <label class="light">{{ $t("DetailStep2.title_fname") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      name
                      v-model="dialogAddFullName.first_name"
                    />
                  </div>

                  <div class="form-group">
                    <label class="light">{{ $t("DetailStep2.title_lname") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      name
                      v-model="dialogAddFullName.last_name"
                    />
                  </div>

                  <div class="buttons pt-3 pb-3">
                    <button
                      :class="
                        !dialogAddFullName.first_name ||
                        !dialogAddFullName.last_name
                          ? 'btn btn-select-area disabled w-160'
                          : 'btn btn-select-area w-160'
                      "
                      type="button"
                      @click="saveNewName()"
                    >
                      <span class="f20">{{ $t("SignupStep4.btn_ok") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </v-dialog>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a :href="pathrightphoto" class="img-exemple iphone-model" data-fancybox>
              <img
                :src="pathrightphoto"
                style="
                  border-radius: 0.938rem !important;
                  object-fit: cover !important;
                "
                alt
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  data: () => ({
    stepdetail: 2,
    propertyID: "",
    userToken: "",
    itemsAcc: [],
    ownerDetail: {
      accommodation_owned_by: "",
      owner_name: [],
      alternative_name: ""
    },
    showDialogAddFullName: false,
    dialogAddFullName: {
      first_name: "",
      last_name: ""
    },
    cangonextstep: false,
    itemsStep: [],
    propertyData: {},
    pathrightphoto: ""
  }),
  created() {
    const self = this;
    self.propertyID = self.$route.params.propertyId;
    self.userToken = self.$route.params.userToken;
    self.initStepData();
    self.initOwnerDetail();
    self.itemsAcc = [
      self.$t("DetailStep2.item_acc1"),
      self.$t("DetailStep2.item_acc2")
    ];
  },
  mounted() {
    EventBus.$on("sendPropertyDetailData", this.getOwnerDetail);
    EventBus.$on("refreshRightPhoto", this.initChangeLanguage);
    this.colorHover();
  },
  watch: {
    ownerDetail() {
      const self = this;
      self.initSetData();
    },
    "ownerDetail.accommodation_owned_by"() {
      const self = this;
      self.initSetData();
    },
    "ownerDetail.owner_name"() {
      const self = this;
      self.initSetData();
    },
    "ownerDetail.alternative_name"() {
      const self = this;
      self.initSetData();
    }
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", e => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    initSetData() {
      const self = this;
      if (
        self?.ownerDetail?.accommodation_owned_by &&
        self?.ownerDetail?.owner_name?.length
      ) {
        self.cangonextstep = true;
      } else {
        self.cangonextstep = false;

        var elementAccOwnedBy = document.getElementById(
          `accommodation_owned_by`
        );
        if (!self.ownerDetail.accommodation_owned_by) {
          elementAccOwnedBy.classList.add("border-pink-focus");
        } else {
          elementAccOwnedBy?.classList?.remove("border-pink-focus");
        }
      }
    },
    async initStepData() {
      const self = this;
      self.itemsStep = [];
      var temp = {};
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          // console.log(res?.data?.result[0])
          res?.data?.result?.map(el => {
            if (el._id == self.propertyID) temp = el;
          });
          self.initDataStep = temp;
          self.keepUserID = res?.data?.result[0]?.user?._id;
          if (res?.data?.result?.length) {
            self.itemsStep = [
              {
                name: self.$t("SignupStep.stepdetail1"),
                active: (self.stepdetail == 1 && true) || false,
                finish: res?.data?.result[0]?.property_detail?.success || false,
                linkItems: {
                  link: `signup/propertydetail/${self.propertyID}`,
                  name: "SignupPropertyDetailScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 1
              },
              {
                name: self.$t("SignupStep.stepdetail2"),
                active: (self.stepdetail == 2 && true) || false,
                finish: res?.data?.result[0]?.owner_detail?.success || false,
                linkItems: {
                  link: `signup/ownerdetail/${self.propertyID}`,
                  name: "SignupOwnerDetailScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 2
              },
              {
                name: self.$t("SignupStep.stepdetail3"),
                active: (self.stepdetail == 3 && true) || false,
                finish: res?.data?.result[0]?.manager_detail?.success || false,
                linkItems: {
                  link: `signup/managerdetail/${self.propertyID}`,
                  name: "SignupManagerDetailScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 3
              },
              {
                name: self.$t("SignupStep.stepdetail4"),
                active: (self.stepdetail == 4 && true) || false,
                finish: self.stepdetail == 5,
                linkItems: {
                  link: `signup/confirmation/${self.propertyID}`,
                  name: "SignupConfirmationScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 4
              }
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.getStepData(self.itemsStep);
      }
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
    },
    changePhotoRight() {
      const self = this;
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/aigent_signup_bg.jpg`;
    },
    initChangeLanguage() {
      const self = this;
      self.initStepData();
    },
    async initOwnerDetail() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          temp = res.data.result;
          self.propertyData = res.data.result[0];
          localStorage.setItem("Property", JSON.stringify(self.propertyData));
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.getOwnerDetail(temp);
      }
    },
    getOwnerDetail(data) {
      const self = this;
      if (data[0]?.owner_detail?.success) {
        self.ownerDetail = {
          accommodation_owned_by:
            data[0]?.owner_detail?.accommodation_owned_by || "",
          owner_name: data[0]?.owner_detail?.owner_name || [],
          alternative_name: data[0]?.owner_detail?.alternative_name || ""
        };
      }
    },
    openDialogAddName() {
      const self = this;
      self.showDialogAddFullName = true;
      self.clearAddName();
    },
    cancelAddName() {
      const self = this;
      self.showDialogAddFullName = false;
      self.clearAddName();
    },
    clearAddName() {
      const self = this;
      self.dialogAddFullName = {
        first_name: "",
        last_name: ""
      };
    },
    saveNewName() {
      const self = this;
      var temp = [];
      temp = self.ownerDetail.owner_name;
      temp.push({
        first_name: self.dialogAddFullName.first_name,
        last_name: self.dialogAddFullName.last_name
      });
      self.ownerDetail.owner_name = temp;
      self.updateOwnerDetail(2);
    },
    removeItemName(item, index) {
      const self = this;
      swal(`ต้องการลบ "${item.first_name} ${item.last_name}" ใช่หรือไม่`, {
        dangerMode: true,
        buttons: {
          cancel: self.$t("SignupStep7.btn_no"),
          confirm: {
            text: self.$t("SignupStep7.btn_yes"),
            value: "confirm"
          }
        }
      }).then(value => {
        if (value === "confirm") {
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.removedatasuccess"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 2000
            }
          ).then(() => {
            self.ownerDetail.owner_name.splice(index, 1);
            self.updateOwnerDetail(2);
          });
        } else {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.notremovedata"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      });
    },
    updateOwnerDetail(num) {
      const self = this;
      const obj = self.ownerDetail;
      // console.log(obj)
      self.updateOwnerDetailFunc(obj, num);
    },
    async updateOwnerDetailFunc(obj, num) {
      const self = this;
      EventBus.$emit("loadingPage");
      var item = {
        link: `signup/managerdetail/${self.propertyID}`,
        name: "SignupManagerDetailScreen",
        params: { propertyId: self.propertyID }
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/owner_detail?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          if (num > 2) self.changeStep(item);
          EventBus.$emit("changeDetailStep", num);
          self.cancelAddName();
        } else {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    changeStep(linkItems) {
      EventBus.$emit("changePathname", linkItems);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/bootstrap/jquery.scrollbar.css";

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.btn.btn-searchmap {
  background-color: var(--pink) !important;
  border-color: var(--pink) !important;
}

.btn.btn-searchmap:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn-cancel-searchmap:hover {
  background-color: #777 !important;
  border-color: #777 !important;
}

.btn-cancel-searchmap:hover span {
  color: #fff !important;
}

.btn-addname {
  width: fit-content;
  cursor: pointer;
}

.btn-addname:hover .btn {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn-addname-disbled {
  width: fit-content;
  cursor: not-allowed;
  .btn {
    cursor: not-allowed;
  }

  .btn.btn-hover-red:hover {
    background-color: #777 !important;
    border-color: #777 !important;
  }

  label {
    cursor: not-allowed !important;
  }
}

.btn-addname-disbled:hover {
  width: fit-content;
  cursor: not-allowed;

  .btn {
    cursor: not-allowed;
    background-color: #777 !important;
    border-color: #777 !important;
  }

  label {
    cursor: not-allowed !important;
  }
}

.btn.btn-hover-red:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}
</style>
